/**
 * pdForms reCAPTCHA v3
 */
import { InteractionEvent } from 'naja/dist/core/UIHandler'

interface RecaptchaData {
	siteKey: string
	action: string
	form: HTMLFormElement
	recaptchaInput: HTMLInputElement
}

class PdFormsRecaptchaV3 {
	public selector = '.g-recaptcha'

	private loadApiScript(siteKey: string): void {
		const script = document.createElement('script')
		script.type = 'text/javascript'
		script.src = 'https://www.google.com/recaptcha/api.js?onload=pdFormsRecaptchaLoadCallback&render=' + siteKey

		document.documentElement.append(script)
	}

	private isAjaxForm(form: HTMLFormElement): boolean {
		if (typeof window.naja === 'undefined') {
			return false
		}

		const ajaxSelector = window.naja.uiHandler.selector

		return form.matches(ajaxSelector) || (form['nette-submittedBy'] && form['nette-submittedBy'].matches(ajaxSelector))
	}

	private doSubmit(token: string, form: HTMLFormElement, recaptchaInput: HTMLInputElement): void {
		recaptchaInput.value = token

		if (this.isAjaxForm(form)) {
			window.naja!.uiHandler.submitForm(form, {
				recaptchaValidated: true
			})
		} else {
			form.submit()
		}
	}

	private validateRecaptcha(event: SubmitEvent | InteractionEvent, data: RecaptchaData) {
		// If the event is already prevented, no submitting is going to happen, therefore no recaptcha need to be
		// called. This might happen e.g. due to invalid form.
		if (event.defaultPrevented) {
			return false
		}

		event.preventDefault()
		event.stopPropagation()

		grecaptcha.ready(() => {
			grecaptcha.execute(data.siteKey, { action: data.action }).then((token: string) => {
				this.doSubmit(token, data.form, data.recaptchaInput as HTMLInputElement)
			})
		})
	}

	private getDataFromRecatpchaElement(element: HTMLElement): RecaptchaData {
		if (!element.dataset.inputId || !element.dataset.sitekey) {
			throw new Error(
				'pdForms.recaptcha: Incomplete dataset on recaptcha element. Either `data-input-id` or `data-sitekey` is missing.'
			)
		}

		const inputId = element.dataset.inputId
		const siteKey = element.dataset.sitekey

		const form = element.closest('form')
		const recaptchaInput = form?.elements.namedItem(inputId) as HTMLInputElement
		const action = element.dataset.action || ''

		if (!form || !recaptchaInput) {
			throw new Error(
				'pdForms.recaptcha: Recaptcha element is either not inside a form, or there no input for recatpcha response was found.'
			)
		}

		return {
			siteKey,
			action,
			form,
			recaptchaInput
		}
	}

	private getRecaptchaElement(form: HTMLFormElement): HTMLElement | null {
		return form.querySelector(this.selector)
	}

	public initForm(htmlId: string): void {
		const form = document.getElementById(htmlId)

		if (form) {
			form.addEventListener(
				'focusin',
				(event: FocusEvent) => {
					this.formFocusinHandler(event, form as HTMLFormElement)
				},
				{ once: true }
			)
		}
	}

	private formFocusinHandler(event: FocusEvent, form: HTMLFormElement): void {
		if (!event.target || !(event.target as HTMLElement).matches('select, input, textarea')) {
			return
		}

		if (typeof grecaptcha === 'undefined') {
			const grecaptchaElement = form.querySelector<HTMLElement>(this.selector)

			if (!grecaptchaElement || !grecaptchaElement.dataset.sitekey) {
				return
			}

			// Ajax handler isn't binded to specific form, we only need to attach it once, when the API is loaded.
			this.attachAjaxHandlers()

			this.loadApiScript(grecaptchaElement.dataset.sitekey)
		} else {
			// Non ajax form handler need to be attached to a potentially new form (e.g. loaded by AJAX).
			this.attachNonAjaxFormHandler(form)
		}
	}

	private attachAjaxHandlers(): void {
		if (typeof window.naja !== 'undefined') {
			window.naja.uiHandler.addEventListener('interaction', (event: InteractionEvent) => {
				const { element, options } = event.detail

				const form = (element as any).form
				if (options.recaptchaValidated || (element.tagName !== 'FORM' && form === undefined)) {
					return
				}

				const recaptchaEl = this.getRecaptchaElement(form)
				if (recaptchaEl) {
					this.validateRecaptcha(event, this.getDataFromRecatpchaElement(recaptchaEl))
				}
			})
		}
	}

	/**
	 * This handles non-ajax forms. All forms have the submit event listner, but those handled by Naja won't get to
	 * execute it.
	 */
	public attachNonAjaxFormHandler(context: HTMLElement | Document): void {
		context = context || document

		context.querySelectorAll<HTMLElement>(this.selector).forEach((item) => {
			;(function (pdFormsRecaptchaV3: PdFormsRecaptchaV3, recaptchaEl) {
				if (recaptchaEl.dataset.recaptchaInitialized) {
					return
				}

				const data = pdFormsRecaptchaV3.getDataFromRecatpchaElement(recaptchaEl)

				if (pdFormsRecaptchaV3.isAjaxForm(data.form)) {
					return
				}

				data.form.addEventListener('submit', (event: SubmitEvent) => {
					pdFormsRecaptchaV3.validateRecaptcha(event, data)
				})

				recaptchaEl.dataset.recaptchaInitialized = String(true)
			})(this, item)
		})
	}
}

const pdForms = window.pdForms || {}
pdForms.recaptcha = new PdFormsRecaptchaV3()

window.pdFormsRecaptchaLoadCallback = (): void => {
	pdForms.recaptcha.attachNonAjaxFormHandler.call(pdForms.recaptcha)
}

window.pdForms = pdForms
